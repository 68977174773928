/*
 * @Author       : your name
 * @Date         : 2020-11-20 16:41:30
 * @LastEditTime : 2020-11-24 20:31:00
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack
// import ECharts modules manually to reduce bundle size
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import './assets/iconfont/iconfont.css'
import VueLazyload from 'vue-lazyload'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import VueCoreVideoPlayer from 'vue-core-video-player'
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')


// app.js
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(ElementUI)
Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})
Vue.use(VideoPlayer)
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// register component to use
// Vue.component('v-chart', ECharts)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(VueLazyload, {
  preLoad: 1,
  error: 'dist/error.png',
  loading: 'dist/loading.gif',
  attempt: 1,
  lazyComponent: true
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
