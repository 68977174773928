<!--
 * @Author       : your name
 * @Date         : 2020-11-20 16:41:30
 * @LastEditTime : 2020-11-30 22:34:34
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/App.vue
-->
<template>
  <div id="app" :style="{ paddingTop: maskTop }">
    <!-- <common-header /> -->
    <router-view></router-view>
    <div class="mask" v-show="displayLoading" :style="{ top: maskTop }">
      <loading />
    </div>

    <!-- <common-footer /> -->
  </div>
</template>

<script>
// import CommonHeader from '@/components/common/Header.vue'
// import CommonFooter from '@/components/common/Footer.vue'
import Loading from '@/components/common/Loading.vue'
import { EventBus } from '@/helper/eventBus'
// import { getUrlRelativePath } from '@/helper/utils'
import { isMobile } from '@/helper/utils'
export default {
  name: 'App',
  data() {
    return {
      displayLoading: true,
      maskTop: '0.85rem'
    }
  },
  components: { Loading },
  methods: {
    //改变font-size

    initFontSize() {
      // eslint-disable-next-line no-extra-semi
      ; (function (doc, win) {
        var docEI = doc.documentElement,
          resizeEvt =
            'orientationchange' in window ? 'orientataionchange' : 'resize',
          recalc = function () {
            var clientWidth = docEI.clientWidth
            if (clientWidth <= 576) {
              //clientWidth = 1366
            }
            if (!clientWidth) return
            // 100是字体大小，1732是开发时浏览器窗口的宽度，等比计算
            if (isMobile()) {
              docEI.style.fontSize = '71px'
            } else {
              docEI.style.fontSize = 100 * (clientWidth / 1920) + 'px'
            }            
          }

        if (!doc.addEventListener) return
        win.addEventListener(resizeEvt, recalc, false)
        doc.addEventListener('DOMContentLoaded', recalc, false)
      })(document, window)
    },
    // 百度统计
    initBaidu() {
      var _hmt = _hmt || []
        ; (function () {
          var hm = document.createElement('script')
          hm.src = '//hm.baidu.com/hm.js?b5efbbc2e17c7fa5fc2caae7bbf7dc85'
          var s = document.getElementsByTagName('script')[0]
          s.parentNode.insertBefore(hm, s)
        })()
    },
    open() {
      this.$confirm('您正在以ie模式浏览网站，部分显示可能会异常, 是否继续?', '提示', {
        confirmButtonText: '继续使用',
        cancelButtonText: '解决方案',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        
      }).catch(() => {
        window.open('https://bbs.360.cn/thread-14958904-1-1.html', '_blank')          
      });
    },
  },
  created () {
    if(!!window.ActiveXObject || "ActiveXObject" in window){
      this.open()
    }
  },
  mounted() {
    this.initFontSize()
    this.initBaidu()
    EventBus.$on('close-loading', () => {
      this.displayLoading = false
    })
    EventBus.$on('open-loading', () => {
      this.displayLoading = true
    })
    EventBus.$on('loading-heigh', () => {
      this.maskTop = 0
    })
    EventBus.$on('loading-low', () => {
      this.maskTop = 0
    })
  }
}
</script>

<style lang="scss">
@import "./styles/sass/vue2-animate.min.css";
#app {
  font-family: "Microsoft YaHei", Arial, sans-serif;
  transition: padding-top 1s;
  .mask {
    position: fixed;
    left: 0;
    top: 0rem;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: #fff;
  }
}
html {
  font-size: 100px;
  overflow-x: hidden;
}
body {
  font-size: 14px !important;
  color: #333;
}
.divider {
  height: 0.04rem;
  background: #fe6917;
  width: 0.4rem;
  margin: 0.1rem auto;
}
.need-gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
// @supports (bottom: env(safe-area-inset-bottom)){
//     body,
//     .footer（底部栏）{
//         padding-bottom: constant(safe-area-inset-bottom);
//         padding-bottom: env(safe-area-inset-bottom);
//     }
// }
.el-pagination__editor.el-input .el-input__inner {
  height: .44rem!important;
  padding: 0 3px!important;
  font-size: 0.15rem!important;
}
.el-button, .el-message-box__headerbtn, .el-dialog__headerbtn {
  outline: none!important;
}
.el-button--primary {
  background-color: #fe6917!important;
  border-color: #fe6917!important;
  font-size: .16rem!important;
}
.el-button--mini, .el-button--small {
  font-size: .16rem!important;
  
  &:hover {
    border-color: #fe6917!important;
    background-color: #fe6917!important;
    color: #ffffff!important;
  }
}
.el-dialog__close.el-icon.el-icon-close {
  font-size: .24rem;
  &:hover {
    color: #fe6917!important;
  }
}
.el-message-box__message {
  padding-top: 10px;
  p {
    font-size: .16rem;
  }
}
</style>
