/*
 * @Author       : your name
 * @Date         : 2020-11-25 15:51:38
 * @LastEditTime : 2020-11-25 15:51:52
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/src/router/routes.js
 */
// 按需（懒）加载
const Home = () => import('@/views/home/Home')

export default [
  {
    path: '/',
    name: '/',
    component: Home,
    props: true,
    meta: {
      keepAlive: false,
      navName: '/',
      title: '首页'
    }
  },
  {
    path: '/aboutUs',
    component: () => import('@/views/aboutUs/index'),
    children: [
      {
        path: '/aboutUs/introduction',
        name: 'Introduction',
        component: () => import('@/views/aboutUs/introduction'),
        props: true,
        meta: {
          title: '明亚简介',
          navName: 'aboutUs'
        }
      },
      {
        path: '/aboutUs/culture',
        name: 'Culture',
        component: () => import('@/views/aboutUs/culture'),
        props: true,
        meta: {
          title: '明亚文化',
          navName: 'aboutUs'
        }
      },
      {
        path: '/aboutUs/honor',
        name: 'Honor',
        component: () => import('@/views/aboutUs/honor'),
        props: true,
        meta: {
          title: '明亚荣誉',
          navName: 'aboutUs'
        }
      },
      {
        path: '/aboutUs/responsibility',
        name: 'responsibility',
        component: () => import('@/views/aboutUs/responsibility'),
        props: true,
        meta: {
          title: '社会责任',
          navName: 'aboutUs',
          keepAlive: true
        }
      },
      {
        path: '/aboutUs/news',
        name: 'news',
        component: () => import('@/views/aboutUs/news'),
        props: true,
        meta: {
          title: '新闻动态',
          navName: 'aboutUs',
          keepAlive: true
        }
      },
      {
        path: '/aboutUs/media',
        name: 'media',
        component: () => import('@/views/aboutUs/media'),
        props: true,
        meta: {
          title: '媒体聚焦',
          navName: 'aboutUs',
          keepAlive: true
        }
      },
      {
        path: '/aboutUs/detail',
        name: 'Detail',
        component: () => import('@/views/aboutUs/detail'),
        props: true,
        meta: {
          title: '详情',
          navName: 'aboutUs'
        }
      },
      {
        path: '/aboutUs/report',
        name: 'report',
        component: () => import('@/views/aboutUs/report'),
        props: true,
        meta: {
          title: '年报',
          navName: 'aboutUs'
        }
      },
      {
        path: '/aboutUs/pdf',
        name: 'reportPdf',
        component: () => import('@/views/aboutUs/reportPdf'),
        props: true,
        meta: {
          title: '年报',
          navName: 'aboutUs'
        }
      }
    ]
  },
  {
    path: 'customerService',
    component: () => import('@/views/customerService/index'),
    children: [
      {
        path: '/customerService/agreement',
        name: 'agreement',
        component: () => import('@/views/customerService/agreement'),
        meta: {
          title: '经纪服务委托协议',
          navName: 'customerService'
        }
      },
      {
        path: '/customerService/notification',
        name: 'notification',
        component: () => import('@/views/customerService/notification'),
        meta: {
          title: '客户告知书',
          navName: 'customerService'
        }
      },
      {
        path: '/customerService/special',
        name: 'special',
        component: () => import('@/views/customerService/special'),
        meta: {
          title: '特色服务',
          navName: 'customerService'
        }
      },
      {
        path: '/customerService/policy',
        name: 'policy',
        component: () => import('@/views/customerService/policy'),
        meta: {
          title: '保单服务',
          navName: 'policyService'
        }
      }
    ]
  },
  {
    path: '/elite',
    redirect: '/eliteStyle/hof/member'
  },
  {
    path: 'eliteStyle',
    component: () => import('@/views/eliteStyle/index'),
    children: [
      {
        path: '/eliteStyle/member',
        redirect: '/eliteStyle/hof/member'
      },
      {
        path: '/eliteStyle/hof',
        redirect: '/eliteStyle/hof/member'
      },
      {
        path: '/eliteStyle/club',
        redirect: '/eliteStyle/hof/club'
      },
      {
        path: '/eliteStyle/hof/member',
        name: 'member',
        component: () => import('@/views/eliteStyle/hof/member'),
        props: true,
        meta: {
          title: '名人堂会员',
          navName: '/'
        }
      },
      {
        path: '/eliteStyle/hof/club',
        name: 'club',
        component: () => import('@/views/eliteStyle/hof/club'),
        meta: {
          title: '名人汇',
          navName: '/'
        }
      },
      {
        path: '/eliteStyle/life',
        name: 'life',
        component: () => import('@/views/eliteStyle/life'),
        meta: {
          title: '明亚生活',
          navName: '/'
        }
      },
      {
        path: '/eliteStyle/meeting',
        name: 'meeting',
        component: () => import('@/views/eliteStyle/meeting'),
        meta: {
          title: '明亚高峰会',
          navName: '/'
        }
      },
      {
        path: '/eliteStyle/elite',
        name: 'elite',
        component: () => import('@/views/eliteStyle/elite'),
        meta: {
          title: '明亚精英',
          navName: '/'
        }
      },
      {
        path: '/eliteStyle/detail',
        name: 'eliteDetail',
        component: () => import('@/views/eliteStyle/detail'),
        meta: {
          title: '详情',
          navName: '/'
        }
      }
    ]
  },
  // {
  //   path: '/website',
  //   name: 'website',
  //   component: () => import('@/views/disclosure/website'),
  //   props: true,
  //   meta: {
  //     title: '合作网站',
  //     keepAlive: true,
  //     navName: '/'
  //   }
  // },
  // {
  //   path: '/disclosure',
  //   name: 'disclosure',
  //   component: () => import('@/views/disclosure/disclosure'),
  //   props: true,
  //   meta: {
  //     title: '产品披露',
  //     keepAlive: true,
  //     navName: '/'
  //   }
  // },
  {
    path: '/member',
    name: 'member',
    component: () => import('@/views/celebrity/member'),
    props: true,
    meta: {
      keepAlive: true,
      navName: '/'
    }
  },
  {
    path: '/contactUs',
    component: () => import('@/views/contactUs/index'),
    children: [
      {
        path: '/contactUs/organization',
        name: 'Organization',
        component: () => import('@/views/contactUs/organization'),
        props: true,
        meta: {
          title: '机构分布',
          navName: '/'
        }
      },
      {
        path: '/contactUs/recruit',
        name: 'Recruit',
        component: () => import('@/views/contactUs/recruit'),
        props: true,
        meta: {
          title: '招贤纳士',
          navName: '/'
        }
      },
      {
        path: '/contactUs/media',
        name: 'Media',
        component: () => import('@/views/contactUs/media'),
        props: true,
        meta: {
          title: '明亚自媒体',
          navName: '/'
        }
      }
    ]
  },
  {
    path: '/mechanism',
    name: 'mechanism',
    component: () => import('@/views/disclosure'),
    props: true,
    meta: {
      title: '信息披露',
      navName: '/'
    },
    children: [
      {
        path: '',
        name: 'Mechanism',
        component: () => import('@/views/disclosure/mechanism'),
        props: true,
        meta: {
          title: '合作机构',
          navName: '/'
        }
      },
      // {
      //   path: '/website',
      //   name: 'Website',
      //   component: () => import('@/views/disclosure/website'),
      //   props: true,
      //   meta: {
      //     title: '合作网站',
      //     navName: '/'
      //   }
      // },
      {
        path: '/OnlineInsurance',
        name: 'OnlineInsurance',
        component: () => import('@/views/disclosure/online-insurance'),
        props: true,
        meta: {
          title: '互联网保险',
          keepAlive: true,
          navName: '/'
        }
      },
      {
        path: '/disclosure',
        name: 'Disclosure',
        component: () => import('@/views/disclosure/disclosure'),
        props: true,
        meta: {
          title: '产品披露',
          navName: '/'
        }
      },
      // #50667
      // {
      //   path: '/systemintroduction',
      //   name: 'SystemIntroduction',
      //   component: () => import('@/views/disclosure/systemIntroduction'),
      //   props: true,
      //   meta: {
      //     title: '系统介绍',
      //     navName: '/'
      //   }
      // },
      {
        path: '/protectPersonInformation',
        name: 'ProtectPersonInformation',
        component: () => import('@/views/disclosure/protectPersonInformation/index'),
        props: true,
        meta: {
          title: '个人信息保护',
          navName: '/'
        }
      },
      {
        path: '/personInformation',
        name: 'PersonInformation',
        component: () => import('@/views/disclosure/protectPersonInformation/personInformation'),
        props: true,
        meta: {
          title: '个人信息收集清单',
          navName: '/'
        }
      },
      {
        path: '/thirdInformation',
        name: 'ThirdInformation',
        component: () => import('@/views/disclosure/protectPersonInformation/thirdInformation'),
        props: true,
        meta: {
          title: '第三方信息共享清单',
          navName: '/'
        }
      }
    ]
  },
  {
    path: '/search',
    name: 'searchIndex',
    component: () => import('@/views/search')
  }
]
