export function getUrlRelativePath () {
  var url = document.location.toString()
  var arrUrl = url.split('//')

  var start = arrUrl[1].indexOf('/')
  var relUrl = arrUrl[1].substring(start) //stop 省略，截取从 start 开始到结尾的所有字符

  if (relUrl.indexOf('?') != -1) {
    relUrl = relUrl.split('?')[0]
  }
  return relUrl
}
export function isMobile () {
  var ua = navigator.userAgent,
        // isWindowsPhone = /(?:Windows Phone)/.test(ua),
        // isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        // isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet;
        // isPc = !isPhone && !isAndroid && !isSymbian;
        return (isAndroid || isPhone)
}
// 低分辨率判断
export function isSmallScreen () {
  var screenWidth = window.screen.width
  if (screenWidth>=1024 && screenWidth <= 1366) {
    return true
  } else {
    return false
  }
}

/**
 * 文件链接转文件流下载--主要针对pdf 解决谷歌浏览器a标签下载pdf直接打开的问题
 * @param url  ：文件链接
 * @param fileName  ：文件名;
 * @param fileType  ：文件类型;
 */
export function fileLink2Download(url, fileName, fileType) {
  var xhr = new XMLHttpRequest();
  xhr.open('get', url, true);
  xhr.setRequestHeader('Content-Type', `application/${fileType}`);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (this.status == 200) {
      var blob = this.response;
      var downloadElement = document.createElement('a');
      var href = blob;
      if (typeof blob == 'string') {
        downloadElement.target = '_blank';
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download = fileName + '.' + fileType; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != 'string') {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    }
  };
  xhr.send();
}

//是否是微信
export function isWXWebview() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf('micromessenger') > -1 && userAgent.indexOf('wxwork') === -1;
}