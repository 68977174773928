/*
 * @Author       : your name
 * @Date         : 2020-11-20 16:46:42
 * @LastEditTime : 2020-11-25 15:52:30
 * @LastEditors  : Please set LastEditors
 * @Description  : In User Settings Edit
 * @FilePath     : /mingya/router.js
 */
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import { EventBus } from '@/helper/eventBus'

import routes from './routes'

const createRouter = () =>
  new Router({
    base: './',
    // scrollBehavior (to, from, savedPosition) {
    //   if (savedPosition) {
    //     return savedPosition
    //   } else {
    //     return {
    //       x: 0,
    //       y: 0
    //     }
    //   }
    // },
    // scrollBehavior: () => ({ x: 0, y: 0 }),
    // scrollBehavior () {
    //   return {}
    // },
    routes
  })
const router = createRouter()

//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    window.document.title = to.meta.title + '-明亚保险经纪'
  } else {
    window.document.title = '明亚保险经纪'
  }
  if (from.path === '/') {
    EventBus.$emit('loading-heigh')
  } else {
    EventBus.$emit('loading-low')
  }
  EventBus.$emit('open-loading')
  next()
})

router.afterEach(to => {
  setTimeout(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
    EventBus.$emit('close-loading')
    EventBus.$emit('router-change', to)
  }, 900)
})
export default router
